import { amplitude } from '@sennder/senn-node-analytics-frontend'
import { AmplitudeLogLevel } from '@sennder/senn-node-analytics-frontend/lib/types'
import { AppAmplitudeLogger } from '@sennder/shell-utilities'

import { AMPLITUDE_API_KEY, isUatEnv } from '@/common/config'
import { getTenant } from '@/plugins/tenants'
import { getStateCompany, getStateData, getStateProfile } from '@/store'
import { executeSafelyWithLogging } from './errors-handler'
import { logger } from './logger/loggers'
import { DEFAULT_EVENT_SOURCE } from './analyticsProvider'

export function initAnalytics() {
  return executeSafelyWithLogging(() => {
    if (isUatEnv()) {
      logger.warn(
        '[orcas-mobile-shell - Analytics]: Amplitude is not available in UAT environment',
        {}
      )
      return
    }
    if (!AMPLITUDE_API_KEY) {
      throw new Error(
        '[orcas-mobile-shell - Analytics]: AMPLITUDE_API_KEY not found'
      )
    }
    amplitude.init({
      token: AMPLITUDE_API_KEY,
      appName: 'carrier-portal',
      amplitudeConfig: {
        minIdLength: 4,
        loggerProvider: new AppAmplitudeLogger(logger),
        logLevel: AmplitudeLogLevel.Warn,
      },
      source: DEFAULT_EVENT_SOURCE,
    })
  }, 'amplitude.init')
}

export function identifyUserInAnalytics(onLogin: boolean) {
  const profile = getStateProfile()
  const cpsCarrier = getStateData().cpsCarrier
  const isInternalUser =
    profile.email.endsWith('@sennder.com') ||
    profile.email.endsWith('@sennder.it')

  const analyticsUser = {
    tenant: getTenant(),
    userId: profile.id,
    sennderSubsidiaryId: cpsCarrier?.billing?.sennder_subsidiary_id,
    language: profile.language,
    hasCarrierPortalAccess: getStateData().hasOrcasAccess,
    isInternalUser,
  }

  const deprecatedUserProps = [
    'userName',
    'Carriers',
    'carrierType',
    'carrier id',
    'carrierId',
    'carrierUuid',
    'exp_ab-first-aa-test',
    'id',
    'hasOrcasAccess',
    'orgType',
    'anonymousId',
    'initial_referrer',
    'initial_referring_domain',
    'referring_domain',
  ]

  executeSafelyWithLogging(
    () =>
      amplitude.identifyUser(
        analyticsUser,
        onLogin,
        {
          groupType: 'carrierUuid',
          groupValue: `${getStateCompany().cpsCarrierId}`,
        },
        deprecatedUserProps
      ),
    'amplitude.identifyUser'
  )
}

export const resetAnalyticsSession = async () => {
  executeSafelyWithLogging(() => amplitude.endSession(), 'amplitude.endSession')
  initAnalytics()
}

// Used when the user rejects analytics cookies,
// all user data will be removed from the analytics integration as well as any event submissions will be terminated
export const stopAnalytics = () => {
  executeSafelyWithLogging(
    () => amplitude.stopTracking(),
    'amplitude.stopTracking'
  )
}
