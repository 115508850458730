import { IAnalyticsContext } from '@sennder/senn-node-microfrontend-interfaces'
import { AnalyticsProvider } from '@sennder/shell-utilities'

import { convertToKebabCase } from '@/modules/utils'
import { logger } from '@/services/logger/loggers'

export const DEFAULT_EVENT_SOURCE = 'carrier-portal-mobile'

const getPageViewEventName = (name: string): string => {
  if (!name) {
    throw Error(
      '[orcas-mobile-shell - Analytics]: Could not track invalid page name'
    )
  }
  // custom formatter because prefix !== module
  return `orcas/view/${convertToKebabCase(name)}-page`
}

const eventMap: Record<string, string> = {
  'orcas-accept-assignment-button-clicked':
    'carrier-portal/click/accept-assignment',
  'orcas-accept-now-request-sent': 'carrier-portal/click/accept-now-confirm',
  'orcas-accept-now-request-success':
    'carrier-portal/system/accept-now-success',
  'orcas-banner-click': 'carrier-portal/click/banner',
  'orcas-make-an-offer-request-sent':
    'carrier-portal/click/make-an-offer-confirm',
  'orcas-marketplace-refresh-button-clicked':
    'carrier-portal/click/assignments-refresh-list',
  'orcas-marketplace-searching-for-offers-from-form':
    'carrier-portal/type/search-form',
  'orcas-marketplace-searching-for-offers-from-query-string':
    'carrier-portal/type/search-query-string',
  'orcas-order-detail-dialog-opened': 'carrier-portal/click/order-detail/open',
  'orcas-reject-assignment-button-clicked':
    'carrier-portal/click/reject-assignment',
  'orcas-suggested-order-details-opened':
    'carrier-portal/click/suggested-order-detail/open',
  'orcas/click/cancel-create-asset': 'carrier-portal/click/cancel-create-asset',
  'orcas/assignments/click/open': 'carrier-portal/click/assignments/open',
  'orcas/auth/click/forgot-password-submit':
    'carrier-portal/click/forgot-password-submit',
  'orcas/auth/click/password-reset-submit':
    'carrier-portal/click/password-reset-submit',
  'orcas/auth/click/password-set-submit':
    'carrier-portal/click/password-set-submit',
  'orcas/auth/result/forgot-password-failed':
    'carrier-portal/system/forgot-password-fail',
  'orcas/auth/result/forgot-password-succeeded':
    'carrier-portal/system/forgot-password-success',
  'orcas/auth/result/login-failed': 'carrier-portal/system/login-fail',
  'orcas/auth/result/password-reset-failed':
    'carrier-portal/system/password-reset-fail',
  'orcas/auth/result/password-reset-succeeded':
    'carrier-portal/system/password-reset-success',
  'orcas/auth/result/password-set-failed':
    'carrier-portal/system/password-set-fail',
  'orcas/auth/result/password-set-succeeded':
    'carrier-portal/system/password-set-success',
  'orcas/auth/view/forgot-password-form':
    'carrier-portal/view/forgot-password-form',
  'orcas/auth/view/login-form': 'carrier-portal/view/login-form',
  'orcas/auth/view/password-reset-form':
    'carrier-portal/view/password-reset-form',
  'orcas/auth/view/password-set-form': 'carrier-portal/view/password-set-form',
  'orcas/click/accept-now-dialog/open': 'carrier-portal/click/accept-now',
  'orcas/click/assignments/open': 'carrier-portal/click/icon-assignments',
  'orcas/click/click-csv-download': 'carrier-portal/click/export-orders',
  'orcas/click/company-profile/open': 'carrier-portal/click/company-profile',
  'orcas/click/contracts/open': 'carrier-portal/click/icon-contracts',
  'orcas/click/date-time': 'carrier-portal/click/assignments-date-time',
  'orcas/click/exit-checkbox': 'carrier-portal/click/green-checkbox-close',
  'orcas/click/exit-popup': 'carrier-portal/click/fleet-popup-exit',
  'orcas/click/fleet/open': 'carrier-portal/click/icon-fleet',
  'orcas/click/invite-team-member/open':
    'carrier-portal/click/invite-team-member',
  'orcas/click/language-list/open': 'carrier-portal/click/language-list',
  'orcas/click/marketplace/open': 'carrier-portal/click/icon-marketplace',
  'orcas/click/notifications/open': 'carrier-portal/click/icon-notifications',
  'orcas/click/onboarding/open': 'carrier-portal/click/onboarding',
  'orcas/click/order': 'carrier-portal/click/payments-order',
  'orcas/click/order-detail/open': 'carrier-portal/click/orders-order',
  'orcas/click/orders/open': 'carrier-portal/click/icon-orders',
  'orcas/click/planner/open': 'carrier-portal/click/icon-planner',
  'orcas/click/save-popup-submission':
    'carrier-portal/click/fleet-popup-submit',
  'orcas/click/tender/cancel-dialog/open':
    'carrier-portal/click/tender-cancel-bid',
  'orcas/click/tender/change-dialog/open':
    'carrier-portal/click/tender-change-bid',
  'orcas/faq/click/open': 'carrier-portal/click/faq/open',
  'orcas/fleet/click/open': 'carrier-portal/click/fleet/open',
  'orcas/language/click/open': 'carrier-portal/click/language/open',
  'orcas/make-an-offer/success': 'carrier-portal/system/make-an-offer-success',
  'orcas/marketplace/click/all-lanes': 'carrier-portal/click/tab-all-lanes',
  'orcas/marketplace/click/cancel-bid': 'carrier-portal/click/cancel-bid',
  'orcas/marketplace/click/cancel-bid-confirm':
    'carrier-portal/click/cancel-bid-confirm',
  'orcas/marketplace/click/cancel-bid-dialog/close':
    'carrier-portal/click/cancel-bid-dialog/close',
  'orcas/marketplace/click/change-bid-confirm':
    'carrier-portal/click/change-bid-confirm',
  'orcas/marketplace/click/edit-saved-lane-confirm':
    'carrier-portal/click/saved-search-edit-confirm',
  'orcas/marketplace/click/edit-saved-lane-dialog/close':
    'carrier-portal/click/edit-saved-lane-dialog/close',
  'orcas/marketplace/click/open': 'carrier-portal/click/marketplace/open',
  'orcas/marketplace/click/saved-lane-confirm':
    'carrier-portal/click/saved-search-save-confirm',
  'orcas/marketplace/click/saved-lane-delete':
    'carrier-portal/click/saved-search-delete-confirm',
  'orcas/marketplace/click/saved-lane-dialog/close':
    'carrier-portal/click/saved-lane-dialog/close',
  'orcas/marketplace/click/saved-lane-dialog/open':
    'carrier-portal/click/saved-search-save',
  'orcas/marketplace/click/saved-lane-edit':
    'carrier-portal/click/saved-lane-edit-confirm',
  'orcas/marketplace/click/saved-lanes': 'carrier-portal/click/tab-saved-lanes',
  'orcas/marketplace/click/sort-by': 'carrier-portal/click/sort-by',
  'orcas/marketplace/click/status-filter':
    'carrier-portal/click/marketplace-status-filter',
  'orcas/marketplace/view/all-lanes': 'carrier-portal/view/tab-all-lanes',
  'orcas/marketplace/view/edit-saved-lane-dialog':
    'carrier-portal/click/saved-search-edit',
  'orcas/marketplace/view/saved-lane': 'carrier-portal/view/saved-lane',
  'orcas/marketplace/view/saved-lanes': 'carrier-portal/view/tab-saved-lanes',
  'orcas/notifications/click/accept-now-succeeded':
    'carrier-portal/click/notification-accept-now-success',
  'orcas/notifications/click/bid-accepted':
    'carrier-portal/click/notification-bid-accepted',
  'orcas/notifications/click/open': 'carrier-portal/click/notifications/open',
  'orcas/onboarding/click/company-submit':
    'carrier-portal/click/onboarding-company-submit',
  'orcas/onboarding/click/contact-submit':
    'carrier-portal/click/onboarding-contact-submit',
  'orcas/onboarding/click/document-upload':
    'carrier-portal/click/onboarding-document-upload',
  'orcas/onboarding/click/open': 'carrier-portal/click/onboarding/open',
  'orcas/onboarding/click/payment-submit':
    'carrier-portal/click/onboarding-payment-submit',
  'orcas/onboarding/click/submit-button':
    'carrier-portal/click/onboarding-submit-button',
  'orcas/onboarding/result/company-failed':
    'carrier-portal/system/onboarding-company-fail',
  'orcas/onboarding/result/company-succeeded':
    'carrier-portal/system/onboarding-company-success',
  'orcas/onboarding/result/contact-failed':
    'carrier-portal/system/onboarding-contact-fail',
  'orcas/onboarding/result/contact-succeeded':
    'carrier-portal/system/onboarding-contact-success',
  'orcas/onboarding/result/document-upload-failed':
    'carrier-portal/system/onboarding-document-upload-fail',
  'orcas/onboarding/result/document-upload-succeeded':
    'carrier-portal/system/onboarding-document-upload-success',
  'orcas/onboarding/result/fetch-failed':
    'carrier-portal/system/onboarding-fetch-fail',
  'orcas/onboarding/result/payment-failed':
    'carrier-portal/system/onboarding-payment-fail',
  'orcas/onboarding/result/payment-succeeded':
    'carrier-portal/system/onboarding-payment-success',
  'orcas/onboarding/result/submit-failed':
    'carrier-portal/system/onboarding-submit-fail',
  'orcas/onboarding/result/submit-succeeded':
    'carrier-portal/system/onboarding-submit-success',
  'orcas/onboarding/view/company-form':
    'carrier-portal/view/onboarding-company-form',
  'orcas/onboarding/view/contact-form':
    'carrier-portal/view/onboarding-contact-form',
  'orcas/onboarding/view/documents-form':
    'carrier-portal/view/onboarding-documents-form',
  'orcas/onboarding/view/form': 'carrier-portal/view/onboarding-form',
  'orcas/onboarding/view/payment-form':
    'carrier-portal/view/onboarding-payment-form',
  'orcas/orders/click/date-time': 'carrier-portal/click/orders-date-time',
  'orcas/orders/click/destination': 'carrier-portal/click/destination',
  'orcas/orders/click/download-credit-note':
    'carrier-portal/click/download-credit-note',
  'orcas/orders/click/download-pod': 'carrier-portal/click/download-pod',
  'orcas/orders/click/open': 'carrier-portal/click/orders/open',
  'orcas/orders/click/origin': 'carrier-portal/click/origin',
  'orcas/orders/click/reference-no': 'carrier-portal/click/reference-no',
  'orcas/orders/click/submit-pod': 'carrier-portal/click/submit-pod',
  'orcas/orders/click/upload-pod': 'carrier-portal/click/upload-pod',
  'orcas/orders/submit-pod-success': 'carrier-portal/system/submit-pod-success',
  'orcas/orders/view-marketplace':
    'carrier-portal/click/orders-view-marketplace',
  'orcas/planner/click/open': 'carrier-portal/click/planner/open',
  'orcas/profile/click/company-profile': 'carrier-portal/click/company-profile',
  'orcas/profile/click/invite-team-member':
    'carrier-portal/click/invite-team-member',
  'orcas/profile/click/open': 'carrier-portal/click/profile/open',
  'orcas/signup/click/submit-button':
    'carrier-portal/click/signup-submit-button',
  'orcas/signup/input': 'carrier-portal/type/signup-input',
  'orcas/signup/input/vat/invalid':
    'carrier-portal/system/signup-input-vat-invalid',
  'orcas/signup/input/vat/valid':
    'carrier-portal/system/signup-input-vat-valid',
  'orcas/signup/lane-preferences/click/lane':
    'carrier-portal/click/signup-lane-preference-add',
  'orcas/signup/lane-preferences/click/skip':
    'carrier-portal/click/signup-lane-preference-skip',
  'orcas/signup/lane-preferences/input/availability':
    'carrier-portal/type/signup-lane-preference-availability',
  'orcas/signup/lane-preferences/input/lane':
    'carrier-portal/type/signup-lane-preference-location',
  'orcas/signup/lane-preferences/input/vehicle-type':
    'carrier-portal/type/signup-lane-preference-vehicle-type',
  'orcas/signup/result/submit-fail': 'carrier-portal/system/signup-submit-fail',
  'orcas/signup/result/submit-succeeded':
    'carrier-portal/system/signup-submit-success',
  'orcas/signup/result/vat-check-failed':
    'carrier-portal/system/vat-check-fail',
  'orcas/signup/result/vat-check-succeeded':
    'carrier-portal/system/vat-check-success',
  'orcas/signup/trigger/captcha': 'carrier-portal/system/captcha-trigger',
  'orcas/signup/trigger/vat-check': 'carrier-portal/system/trigger-vat-check',
  'orcas/signup/view/basic-info-form': 'carrier-portal/view/basic-info-form',
  'orcas/signup/view/lane-preferences': 'carrier-portal/view/lane-preferences',
  'orcas/submit-pod-fail': 'carrier-portal/system/submit-pod-fail',
  'orcas/terms/click/terms-accept-submit':
    'carrier-portal/click/terms-accept-submit',
  'orcas/terms/view/terms-popup': 'carrier-portal/view/terms-popup',
  'orcas/upload-pod-fail': 'carrier-portal/system/upload-pod-fail',
  'orcas/upload-pod-success': 'carrier-portal/system/upload-pod-success',
  'orcas/view/senn-f-u-e-l-l-p-page': 'carrier-portal/view/senn-fuel-lp-page',
  'orcas/view/senn-f-u-e-l-page': 'carrier-portal/view/senn-fuel-page',
  'tms-assignment-accepted-notification-clicked':
    'carrier-portal/click/notification-assignment-accepted',
  'tms-assignment-canceled-notification-clicked':
    'carrier-portal/click/notification-assignment-canceled',
  'tms-assignment-created-notification-clicked':
    'carrier-portal/click/notification-assignment-created',
  'tms-click-driver-add': 'carrier-portal/click/driver-add',
  'tms-click-driver-delete': 'carrier-portal/click/driver-delete-confirm',
  'tms-click-driver-edit': 'carrier-portal/click/driver-edit',
  'tms-click-order-assign-truck': 'carrier-portal/click/order-assign-truck',
  'tms-click-order-edit': 'carrier-portal/click/order-edit',
  'tms-click-order-find': 'carrier-portal/click/order-find',
  'tms-click-trailer-add': 'carrier-portal/click/trailer-add',
  'tms-click-trailer-delete': 'carrier-portal/click/trailer-delete-confirm',
  'tms-click-trailer-duplicate': 'carrier-portal/click/trailer-duplicate',
  'tms-click-trailer-edit': 'carrier-portal/click/trailer-edit',
  'tms-click-truck-add': 'carrier-portal/click/truck-add',
  'tms-click-truck-delete': 'carrier-portal/click/truck-delete-confirm',
  'tms-click-truck-duplicate': 'carrier-portal/click/truck-duplicate',
  'tms-click-truck-edit': 'carrier-portal/click/truck-edit',
  'tms-driver-add': 'carrier-portal/click/driver-add-confirm',
  'tms-driver-edit': 'carrier-portal/click/driver-edit-confirm',
  'tms-error': 'carrier-portal/system/error',
  'tms-file-deleted': 'carrier-portal/click/file-delete',
  'tms-file-uploaded': 'carrier-portal/click/upload-file',
  'tms-language-change': 'carrier-portal/click/language-change-confirm',
  'tms-tms-file-uploaded': 'carrier-portal/system/upload-file',
  'tms-trailer-add': 'carrier-portal/click/trailer-add-confirm',
  'tms-trailer-edit': 'carrier-portal/click/trailer-edit-confirm',
  'tms-truck-add': 'carrier-portal/click/truck-add-confirm',
  'tms-truck-edit': 'carrier-portal/click/truck-edit-confirm',
}

/**
 * This is a temporary solution to fix the event prefix name issue.
 * Ideally each microfrontend should not define the prefix name, but should be defined in the shell.
 */
const getTrackEventName = (name: string): string => {
  const eventName = eventMap[name] || name
  if (eventName.startsWith('orcas/')) {
    return eventName.replace('orcas/', 'carrier-portal/')
  }

  if (eventName.startsWith('tms/')) {
    return eventName.replace('tms/', 'carrier-portal/')
  }

  // If events has no prefix
  if (!eventName.startsWith('carrier-portal/')) {
    return `carrier-portal/${eventName}`.replace(/\/\//g, '/')
  }

  return eventName
}

export class AppAnalyticsProvider extends AnalyticsProvider {
  // enforce app specific analytics configuration
  constructor(context: IAnalyticsContext) {
    super({
      context,
      logger,
      messageSource: DEFAULT_EVENT_SOURCE,
      dedupePageEvents: true,
      formatEventName: getTrackEventName,
      formatPageEventName: getPageViewEventName,
    })
  }
}

export const analytics = new AppAnalyticsProvider({
  module: 'shell',
  submodule: '',
})
